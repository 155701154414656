import "./style.scss";

export default function render() {
    if (this.props.showingImages === null || this.state.index === null) {
        return;
    }

    const date = new Date(this.props.images[this.state.index].date_taken);
    return (
        <div className="image-wrapper">

            <div className="top" onClick={() => this.props.setShowingImages(null)}>
                <i className="fa fa-times fa-2x"></i>
            </div>

            <div className="top panorama-icon">
                {this.props.images[this.state.index].panorama ? (
                    <img alt="360 panorama" src={"/360.png"} />
                ) : null}
            </div>
            
            <div className="previous" onClick={this.goLeft}>
                <i className="fa fa-chevron-left"></i>
            </div>
            <div className="next" onClick={this.goRight}>
                <i className="fa fa-chevron-right"></i>
            </div>

            <div
                className="shadow"
                onTouchStart={this.touchStart.bind(this)}
                onTouchMove={this.touchMove.bind(this)}
                onTouchEnd={this.touchEnd.bind(this)}
            />
            <div className="image"
            onTouchStart={this.touchStart.bind(this)}
            onTouchMove={this.touchMove.bind(this)}
            onTouchEnd={this.touchEnd.bind(this)}>
                {this.state.loading ? (
                    <img src="/loading.gif" alt="Loading" style={{width: "100px"}} />
                ) : null}
                {this.props.images[this.state.index].panorama ? (
                    <div
                        style={this.state.loading ? {display: "none", width: "100%"} : {}}
                        id="panorama"
                    >
                        <div id="controls">
                            <div className="ctrl" id="pan-up"><i className="fa fa-caret-up"></i></div>
                            <div className="ctrl" id="pan-down"><i className="fa fa-caret-down"></i></div>
                            <div className="ctrl" id="pan-left"><i className="fa fa-caret-left"></i></div>
                            <div className="ctrl" id="pan-right"><i className="fa fa-caret-right"></i></div>
                            <div className="ctrl" id="zoom-in"><i className="fa fa-plus"></i></div>
                            <div className="ctrl" id="zoom-out"><i className="fa fa-minus"></i></div>
                            <div className="ctrl" id="fullscreen"><i className="fa fa-expand"></i></div>
                        </div>
                    </div>
                ): (
                    <img
                        src={"https://kirke-foto.dk/images/high_" + this.props.images[this.state.index].path}
                        alt={this.props.images[this.state.index].description}
                        style={this.state.loading ? {display: "none"} : {}}
                        onLoad={this.onImageLoad}
                    />
                )}
            </div>
            <div className="comment">
            Taget: {date.toLocaleDateString('da')}<br />{this.props.images[this.state.index].description}
            </div>
        </div>
    );
}