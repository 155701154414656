import React, { useEffect } from "react";
import SidebarMain from "../../components/sidebar-main/component";
import { Wrapper } from "@googlemaps/react-wrapper";
import SingleMap from "./single-map";
import { connect } from "react-redux";
import { dioceses, dioceseState } from "../../redux/features/diocese/selectors";
import { parishes, parishState } from "../../redux/features/parish/selectors";
import { deaneries, deaneryState } from "../../redux/features/deanery/selectors";
import { churches, churchState } from "../../redux/features/church/selectors";
import { STATE } from "../../redux/constants";


const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      const newMarker = new window.google.maps.Marker();
      setMarker(newMarker);

      const contentString =
        '<div id="content">' +
        '<h1 class="church-header">'+ (options.church ? options.church.name : '') + '</h1>' +
        '<span class="church-breadcrumb">' +
        (options.church.parish ? options.church.parish.name : '') + ' - ' + (options.church.deanery ? options.church.deanery.name : '') + ' - ' + (options.church.diocese ? options.church.diocese.name : '') +
        '</span>' +
        '<a class="church-button" href="/kirke/'+options.church.parish.url+'/'+options.church.url+'"><button class="btn btn-sm btn-primary">Gå til '+options.church.name+'&nbsp;&nbsp;<i class="fa fa-chevron-right"></i></button></a>' +
        "</div>";
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      newMarker.addListener("click", () => {
        infowindow.open({
          anchor: newMarker,
          shouldFocus: false,
        });
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, options]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};


function Map(props) {
  const ref = React.useRef(null);
  const [map] = React.useState();
  const [zoom, setZoom] = React.useState(7); // initial zoom
  const [center, setCenter] = React.useState({
    lat: 56.43031025601471,
    lng: 10.713182042019326
  });

  useEffect(() => {
    document.title = props.title + " - Kirke-Foto" || "";

    const meta = document.getElementsByTagName('meta');
    meta['og:title'].content = document.title;
    meta['og:description'].content = props.shortDescription;
    meta['og:image'].content = "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + props.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = props.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";

    if (ref.current && !map) {
      new window.google.maps.Map(ref.current, {
        center,
        zoom
      });
    }
  
  }, [props.title, ref, map, center, zoom, props.description, props.shortDescription, props.keywords]);
  

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  const [clicks, setClicks] = React.useState([]);

  const onClick = (e) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng]);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };
  if (
    !(props.parishState === STATE.IS_FETCHED &&
    props.churchState === STATE.IS_FETCHED &&
    props.deaneryState === STATE.IS_FETCHED &&
    props.dioceseState === STATE.IS_FETCHED)
  ) {
    return;
  }

  let churches = props.churches.map(church => {
    church.parish = props.parishes.find(parish => parish.id === church.parish_id);
    church.deanery = props.deaneries.find(deanery => deanery.id === church.parish.deanery_id);
    church.diocese = props.dioceses.find(diocese => diocese.id === church.deanery.diocese_id);

    return church;
  });

  window.prerenderReady = true;

  return (
    <>
      <SidebarMain className="map">
        <h1>Kort</h1>

        <p>Her er det muligt at få et kort over alle de kirker, som jeg har besøgt og uploadet billeder til.</p>
        <Wrapper apiKey={"AIzaSyD1O33PxYdUCfITPpJblqE9yk_k5BtiIcA"} render={render}>
        <SingleMap
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          streetViewControl={false}
          style={{ flexGrow: "1", height: "600px" }}
        >
          {churches.map(church => (
            <Marker
              icon="https://api.kirke-foto.dk/church-red.png"
              key={church.id}
              church={church}
              position={{
                lat: parseFloat(church.latitude),
                lng: parseFloat(church.longitude),
              }}
            />
          ))}
        </SingleMap>
        </Wrapper>
      </SidebarMain>
    </>
  );
}

const mapStateToProps = state => ({
  dioceses: dioceses(state),
  parishes: parishes(state),
  deaneries: deaneries(state),
  churches: churches(state),
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {})(Map);