import { combineReducers } from "redux";
import diocese from "./features/diocese/reducers";
import parish from "./features/parish/reducers";
import deanery from "./features/deanery/reducers";
import church from "./features/church/reducers";


export default combineReducers({
    diocese,
    parish,
    deanery,
    church,
});
