import React from "react";
import Photos from "../../components/photos/component";
import "./style.scss";

export default function render() {
    if (this.state.loading) {
        return (
            <>Loading</>
        )
    }
    return (
        <div>
        <>
            <h1 className="church-header">{this.state.church ? this.state.church.name : ''}</h1>
            <span className="church-breadcrumb">
                {this.state.parish ? this.state.parish.name : ''} - {this.state.deanery ? this.state.deanery.name : ''} - {this.state.diocese ? this.state.diocese.name : ''}
            </span>
            <p>{this.state.parish ? (<a href={"https://sogn.dk/"+this.state.parish.url} target="_blank" rel="noreferrer">Gå til {this.state.parish.name} via sogn.dk</a>) : ''}</p>
            <div className="church-text">
                Det er muligt at få billederne i fuld opløsning, hvis man er medlem af kirkens personale eller menighedsråd.
            </div>
            <br />
            <div className="photos">
                {this.state.images ? this.state.images.map((image, key) => (
                    <div className="photo" key={image.id} onClick={() => this.openImage(key)}>
                        <img alt={image.name} src={"https://kirke-foto.dk/images/thumb_"+image.path} />
                        {image.panorama ? (
                            <img alt="360 panorama" className="panorama-image" src={"/360.png"} />
                        ) : null}
                    </div>
                )) : null}
            </div>
        </>
        <Photos />
        </div>
    );
}
