import { createSelector } from "reselect";
const stateI = state => state.parish.state;
const parishesI = state => state.parish.parishes;

export const parishState = createSelector(
    [stateI],
    state => state
);

export const parishes = createSelector(
    [parishesI],
    parishes => parishes
);
