import React from 'react';
import view from "./view";
import { connect } from "react-redux";
import { dioceses, dioceseState } from './../../redux/features/diocese/selectors';
import { parishes, parishState } from './../../redux/features/parish/selectors';
import { deaneries, deaneryState } from './../../redux/features/deanery/selectors';
import { churches, churchState } from './../../redux/features/church/selectors';
import { STATE } from '../../redux/constants';

class Search extends React.Component {
    dioceseSelect = React.createRef();
    deanerySelect = React.createRef();
    parishSelect = React.createRef();
    churchSelect = React.createRef();
    refreshing = false;

    constructor(props) {
        super(props);
        this.state = {
            dioceses: [],
            deaneries: [],
            parishes: [],
            churches: [],
            selectedDiocese: null,
            selectedDeanery: null,
            selectedParish: null,
            selectedChurch: null,
            showChurchesSelect: true,
            showChurchesButtons: false,
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.dioceseState === STATE.IS_FETCHED) {
            this.setState({
                dioceses: this.props.dioceses.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }

        if (this.props.deaneryState === STATE.IS_FETCHED) {
            this.setState({
                deaneries: this.props.deaneries.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }

        if (this.props.parishState === STATE.IS_FETCHED) {
            this.setState({
                parishes: this.props.parishes.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }

        if (this.props.churchState === STATE.IS_FETCHED) {
            this.setState({
                churches: this.props.churches.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dioceseState !== this.props.dioceseState && this.props.dioceseState === STATE.IS_FETCHED) {
            this.setState({
                dioceses: this.props.dioceses.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }
        if (prevProps.deaneryState !== this.props.deaneryState && this.props.deaneryState === STATE.IS_FETCHED) {
            this.setState({
                deaneries: this.props.deaneries.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }
        if (prevProps.parishState !== this.props.parishState && this.props.parishState === STATE.IS_FETCHED) {
            this.setState({
                parishes: this.props.parishes.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }
        if (prevProps.churchState !== this.props.churchState && this.props.churchState === STATE.IS_FETCHED) {
            this.setState({
                churches: this.props.churches.map(diocese => ({ label: diocese.name, value: diocese.id })),
            });
        }
    }

    onDioceseChange = diocese => {
        if (this.refreshing) { return }
        this.refreshing = true;
        if (!diocese) {
            this.setState({
                deaneries: this.props.deaneries.map(deanery => ({ label: deanery.name, value: deanery.id })),
                parishes: this.props.parishes.map(parish => ({ label: parish.name, value: parish.id })),
                churches: this.props.churches.map(church => ({ label: church.name, value: church.id })),
                showChurchesSelect: true,
                showChurchesButtons: false,
                selectedDiocese: null,
            }, () => {
                this.deanerySelect.current.clear();
                this.parishSelect.current.clear();
                this.churchSelect.current.clear();
                this.refreshing = false;
            });
            return;
        }
        const deaneries = this.props.deaneries.filter(deanery => deanery.diocese_id === diocese.value);
        const deaneriesId = deaneries.map(deanery => deanery.id);
        const parishes = this.props.parishes.filter(parish => deaneriesId.includes(parish.deanery_id));
        const parishesId = parishes.map(parish => parish.id);
        const churches = this.props.churches.filter(church => parishesId.includes(church.parish_id));
        this.setState({
            selectedDiocese: diocese,
            deaneries: deaneries.map(data => ({ label: data.name, value: data.id })),
            parishes: parishes.map(data => ({ label: data.name, value: data.id })),
            churches: churches.map(data => ({ label: data.name, value: data.id })),
            showChurchesSelect: true,
            showChurchesButtons: false,
        }, () => {
            this.refreshing = false;
            this.deanerySelect.current.clear();
            this.parishSelect.current.clear();
            this.churchSelect.current.clear();
        });
    }

    onDeaneryChange = deaneryData => {
        if (this.refreshing) { return }
        this.refreshing = true;
        if (!deaneryData) {
            if (!this.state.selectedDiocese) { return }
            const diocese = this.state.selectedDiocese;
            const deaneries = this.props.deaneries.filter(deanery => diocese.value === deanery.diocese_id);
            const deaneriesId = deaneries.map(deanery => deanery.id);
            const parishes = this.props.parishes.filter(parish => deaneriesId.includes(parish.deanery_id));
            const parishesId = parishes.map(parish => parish.id);
            const churches = this.props.churches.filter(church => parishesId.includes(church.parish_id));
            
            this.setState({
                parishes: parishes.map(data => ({ label: data.name, value: data.id })),
                churches: churches.map(data => ({ label: data.name, value: data.id })),
                showChurchesSelect: true,
                showChurchesButtons: false,
                selectedDeanery: null,
                selectedParish: null,
                selectedChurch: null,
            }, () => {
                this.parishSelect.current.clear();
                this.churchSelect.current.clear();
                this.refreshing = false;
            });
            return;
        }
        const deanery = this.props.deaneries.find(deanery => deanery.id === deaneryData.value);
        const diocese = this.props.dioceses.find(diocese => diocese.id === deanery.diocese_id);
        const deaneries = this.props.deaneries.filter(deanery => diocese.id === deanery.diocese_id);
        const parishes = this.props.parishes.filter(parish => deanery.id === parish.deanery_id);
        const parishesId = parishes.map(parish => parish.id);
        const churches = this.props.churches.filter(church => parishesId.includes(church.parish_id));

        this.dioceseSelect.current.select({ label: diocese.name, value: diocese.id });

        this.setState({
            selectedDiocese: { label: diocese.name, value: diocese.id },
            selectedDeanery: deaneryData,
            selectedParish: null,
            selectedChurch: null,
            deaneries: deaneries.map(data => ({ label: data.name, value: data.id })),
            parishes: parishes.map(data => ({ label: data.name, value: data.id })),
            churches: churches.map(data => ({ label: data.name, value: data.id })),
            showChurchesButtons: false,
            showChurchesSelect: true,
        }, () => {
            this.refreshing = false;
            this.parishSelect.current.clear();
            this.churchSelect.current.clear();
        });
    }

    onParishChange = parishData => {
        if (this.refreshing) { return }
        this.refreshing = true;
        if (!parishData) {
            if (!this.state.selectedDeanery) { return }
            const deanery = this.state.selectedDeanery;
            const parishes = this.props.parishes.filter(parish => deanery.value === parish.deanery_id);
            const parishesId = parishes.map(parish => parish.id);
            const churches = this.props.churches.filter(church => parishesId.includes(church.parish_id));

            this.setState({
                churches: churches.map(data => ({ label: data.name, value: data.id })),
                showChurchesSelect: true,
                showChurchesButtons: false,
                selectedParish: null,
                selectedChurch: null,
            }, () => {
                this.churchSelect.current.clear();
                this.refreshing = false;
            });
            return;
        }
        const parish = this.props.parishes.find(parish => parish.id === parishData.value);
        const deanery = this.props.deaneries.find(deanery => deanery.id === parish.deanery_id);
        const diocese = this.props.dioceses.find(diocese => diocese.id === deanery.diocese_id);

        const dioceses = this.props.dioceses.filter(diocese => diocese.id === deanery.diocese_id);
        const diocesesId = dioceses.map(diocese => diocese.id);
        const deaneries = this.props.deaneries.filter(deanery => diocesesId.includes(deanery.diocese_id));
        const churches = this.props.churches.filter(church => parish.id === church.parish_id);

        this.dioceseSelect.current.select({ label: diocese.name, value: diocese.id });
        this.deanerySelect.current.select({ label: deanery.name, value: deanery.id });

        let selectedChurch = null;
        let showChurchesButtons = false;
        if (churches.length === 1) {
            if (this.churchSelect.current) {
                this.churchSelect.current.select({ label: churches[0].name, value: churches[0].id });
            }
            selectedChurch = { label: churches[0].name, value: churches[0].id };
            showChurchesButtons = true;
        }

        this.setState({
            selectedDiocese: { label: diocese.name, value: diocese.id },
            selectedDeanery: { label: deanery.name, value: deanery.id },
            selectedParish: parishData,
            selectedChurch,
            deaneries: deaneries.map(data => ({ label: data.name, value: data.id })),
            churches: churches.map(data => ({ label: data.name, value: data.id })),
            showChurchesSelect: false,
            showChurchesButtons,
        }, () => {
            this.refreshing = false;
        });
    }

    onChurchChange = churchData => {
        if (this.refreshing) { return }
        this.refreshing = true;
        if (!churchData) {
            this.setState({
                showChurchesSelect: true,
                showChurchesButtons: false,
                selectedChurch: null,
            }, () => {
                this.refreshing = false;
            });
            return;
        }
        const church = this.props.churches.find(church => church.id === churchData.value);
        const parish = this.props.parishes.find(parish => parish.id === church.parish_id);
        const deanery = this.props.deaneries.find(deanery => deanery.id === parish.deanery_id);
        const diocese = this.props.dioceses.find(diocese => diocese.id === deanery.diocese_id);

        const dioceses = this.props.dioceses.filter(diocese => diocese.id === deanery.diocese_id);
        const diocesesId = dioceses.map(diocese => diocese.id);
        const deaneries = this.props.deaneries.filter(deanery => diocesesId.includes(deanery.diocese_id));
        const parishes = this.props.parishes.filter(parish => deanery.id === parish.deanery_id);
        const churches = this.props.churches.filter(church => parish.id === church.parish_id);

        this.dioceseSelect.current.select({ label: diocese.name, value: diocese.id });
        this.deanerySelect.current.select({ label: deanery.name, value: deanery.id });
        this.parishSelect.current.select({ label: parish.name, value: parish.id });

        this.setState({
            selectedDiocese: { label: diocese.name, value: diocese.id },
            selectedDeanery: { label: deanery.name, value: deanery.id },
            selectedParish: { label: parish.name, value: parish.id },
            selectedChurch: churchData,
            deaneries: deaneries.map(data => ({ label: data.name, value: data.id })),
            parishes: parishes.map(data => ({ label: data.name, value: data.id })),
            churches: churches.map(data => ({ label: data.name, value: data.id })),
            showChurchesButtons: true,
        }, () => {
            this.refreshing = false;
        });
    }

    onFormSubmit = event => {
        console.log(this.state);
        event.preventDefault();
    }

    onFormChange = event => {
        console.log(event);
    }

    render = view;
}

const mapStateToProps = state => ({
    dioceses: dioceses(state),
    parishes: parishes(state),
    deaneries: deaneries(state),
    churches: churches(state),
    dioceseState: dioceseState(state),
    parishState: parishState(state),
    deaneryState: deaneryState(state),
    churchState: churchState(state),
});

export default connect(mapStateToProps, {})(Search);
