import { createRef, useEffect, useState } from "react";
import SidebarMain from "../../components/sidebar-main/component";
import SidebarWidget from "../../components/sidebar-widget/component";
import Sidebar from "../../components/sidebar/component";

function Contact(props) {
  useEffect(() => {
    document.title = props.title + " - Kirke-Foto" || "";
    const meta = document.getElementsByTagName('meta');
    meta['og:title'].content = document.title;
    meta['og:image'].content = "https://kirke-foto.dk/me_2.jpeg";
    meta['og:description'].content = props.shortDescription;
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + props.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = props.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";

    generateSecurity();
    window.prerenderReady = true;
  }, [props.title, props.description, props.shortDescription, props.keywords]);


  function generateSecurity() {
    const math1 = Math.floor(Math.random() * 10) + 1;
    const math2 = Math.floor(Math.random() * 10) + 1;
    setSecurityText(math1 + ' + ' + math2);
    setSecurityAnswer(math1+math2);
    setSecurity(0);
    securityRef.current.value = '';
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [security, setSecurity] = useState(0);
  const [securityText, setSecurityText] = useState('');
  const [securityAnswer, setSecurityAnswer] = useState(0);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);

  const nameRef = createRef();
  const emailRef = createRef();
  const subjectRef = createRef();
  const messageRef = createRef();
  const securityRef = createRef();

  const onNameChange = event => {
    setName(event.target.value);
  }

  const onEmailChange = event => {
    setEmail(event.target.value);
  }

  const onSubjectChange = event => {
    setSubject(event.target.value);
  }

  const onMessageChange = event => {
    setMessage(event.target.value);
  }

  const onSecurityChange = event => {
    setSecurity(event.target.value);
  }

  const sendMessage = () => {
    const errors = [];
    if (parseInt(security) !== parseInt(securityAnswer)) {
      errors.push('Fejl i sikkerhedstjek, prøv igen.');
    }
    if (name.length === 0) {
      errors.push('Udfyld venligst dit navn');
    }
    if (email.length === 0) {
      errors.push('Udfyld venligst din email');
    } else {
      var mail_format = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!email.match(mail_format)) {
        errors.push('Emailen er ikke gyldig');
      }
    }
    if (subject.length === 0) {
      errors.push('Udfyld venligst et emne');
    }
    if (message.length === 0) {
      errors.push('Udfyld venligst en besked');
    }
    if (errors.length > 0) {
      setErrors(errors);
      generateSecurity();
    } else {
      console.log(name, email, subject, message);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('subject', subject);
      formData.append('message', message);
      fetch("https://api.kirke-foto.dk/churches/contact", { method: 'POST', body: formData, credentials: 'include'})
        .then(
            (result) => {
              setName('');
              nameRef.current.value = ''
              setEmail('');
              emailRef.current.value = ''
              setSubject('');
              subjectRef.current.value = ''
              setMessage('');
              messageRef.current.value = ''
              setErrors(null);
              setSuccess('Beskeden er blevet sendt.')
              generateSecurity();
            },
            (error) => {
              setErrors('Fejl, kunne ikke sende beskeden');
            }
      );
    }
  }

  return (
    <>
      <SidebarMain className="about-us">
        <h1>Kontakt</h1>
        {errors !== null && errors.length > 0 ? (
          <>{errors.map(error => (<p style={{color: "#f00"}}>{error}</p>))}</>
        ):null}
        {success !== null && success.length > 0 ? (
          <p>{success}</p>
        ):null}
        <div className="form-group">
          <label>Navn *</label>
          <input type="text" ref={nameRef} onChange={onNameChange} className="form-control" />
        </div>
        <div className="form-group">
          <label>Email *</label>
          <input type="email" ref={emailRef} onChange={onEmailChange} className="form-control" />
        </div>
        <div className="form-group">
          <label>Emne *</label>
          <input type="text" ref={subjectRef} onChange={onSubjectChange} className="form-control" />
        </div>
        <div className="form-group">
          <label>Besked *</label>
          <textarea onChange={onMessageChange} ref={messageRef} className="form-control" rows="5" />
        </div>
        <div className="form-group">
          <label>Sikkerhedstjek *</label>
          <p>Skriv resultatet i feltet herunder: {securityText}</p>
          <input type="number" ref={securityRef} onChange={onSecurityChange} className="form-control" />
        </div>
        <p>* Disse felter skal udfyldes</p>
        <button onClick={sendMessage} className="btn btn-primary">Send</button>
      </SidebarMain>
      <Sidebar>
        <SidebarWidget noPadding>
          <img src="/me_2.jpeg" alt="René Dyhr" className="image about-us-image" />
        </SidebarWidget>
        <SidebarWidget title="Adresse & Telefon Nr.">
          <p style={{marginBottom: 5}}>René Dyhr</p>
          <p style={{marginBottom: 5}}>Miliegræsset 89</p>
          <p style={{marginBottom: 5}}>5220 Odense SØ, Danmark</p>
          <p style={{marginBottom: 5}}>+45 27 84 78 08</p>
        </SidebarWidget>
      </Sidebar>
    </>
  );
}

export default Contact;
