import { useEffect } from "react";

function NotFound(props) {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <div>
      <>
      NotFound Page
      </>
    </div>
  );
}

export default NotFound;
