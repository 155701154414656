import React from "react";
import "./style.scss";

const SidebarWidget = ({title, children, noPadding}) => (
  <div className={["card", noPadding ? 'no-padding':''].join(' ')}>
    <div className="card-body">
      { title ? (<h5 className="card-title">{title}</h5>) : null}
      <div className="card-text">
        {children}
      </div>
    </div>
  </div>
);

export default SidebarWidget;
