import { STATE } from "../../constants";
import {
    DONE_FETCHED_DIOCESE,
    FAILED_FETCHED_DIOCESE,
    FETCH_DIOCESE,
} from "./constants";

export default function diocese(
    state = {
        state: STATE.NOT_FETCHED,
        dioceses: []
    },
    action
) {
    switch (action.type) {
        case FETCH_DIOCESE:
            return {
                ...state,
                state: STATE.FETCHING,
            };
        case FAILED_FETCHED_DIOCESE:
                return {
                    ...state,
                    state: STATE.FAILED_FETCHING,
                };
        case DONE_FETCHED_DIOCESE:
            return {
                ...state,
                dioceses: action.payload,
                state: STATE.IS_FETCHED,
            };
        default:
            return state;
    }
}
