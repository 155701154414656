import { createSelector } from "reselect";
const stateI = state => state.deanery.state;
const deaneriesI = state => state.deanery.deaneries;

export const deaneryState = createSelector(
    [stateI],
    state => state
);

export const deaneries = createSelector(
    [deaneriesI],
    deaneries => deaneries
);
