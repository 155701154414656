import React from "react";
import "./style.scss";
import Select from 'react-select-virtualized';
import { NavLink } from "react-router-dom";

export default function render() {
    const churches = this.state.selectedChurch ? [this.state.selectedChurch] : this.state.churches;
    return (
        <>
            <div className="form-group">
                <label>Stift</label>
                <Select
                ref={this.dioceseSelect}
                placeholder="Vælg stift..."
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                options={this.state.dioceses}
                onChange={this.onDioceseChange}
                isDisabled={this.props.dioceseState !== "IS_FETCHED"}
                />
            </div>
            <div className="form-group">
                <label>Provsti</label>
                <Select
                ref={this.deanerySelect}
                placeholder="Vælg provsti..."
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                options={this.state.deaneries}
                onChange={this.onDeaneryChange}
                isDisabled={this.props.deaneryState !== "IS_FETCHED"}
                />
            </div>

            <div className="form-group">
                <label>Sogn</label>
                <Select
                ref={this.parishSelect}
                placeholder="Vælg sogn..."
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                options={this.state.parishes}
                onChange={this.onParishChange}
                isDisabled={this.props.parishState !== "IS_FETCHED"}
                />
            </div>

            {this.state.showChurchesSelect ? (
            <div className="form-group">
                <label>Kirke</label>
                <Select
                ref={this.churchSelect}
                placeholder="Vælg kirke..."
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                options={this.state.churches}
                onChange={this.onChurchChange}
                className="smaller-select"
                isDisabled={this.props.churchState !== "IS_FETCHED"}
                />
            </div>) : null }
            {!this.state.showChurchesSelect || this.state.showChurchesButtons ? churches.map(church => (
                <React.Fragment key={church.value}>
                    <NavLink to={"/kirke/" + this.props.parishes.find(parish => parish.id === this.state.selectedParish.value).url + "/" + this.props.churches.find(propChurch => propChurch.id === church.value).url}>
                        <button className="btn btn-primary">Gå til {church.label}&nbsp;&nbsp;<i className="fa fa-chevron-right"></i></button><br /><br />
                    </NavLink>
                </React.Fragment>
            )): null}
        </>
    );
}