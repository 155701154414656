import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dioceses, dioceseState } from './../../redux/features/diocese/selectors';
import { parishes, parishState } from './../../redux/features/parish/selectors';
import { deaneries, deaneryState } from './../../redux/features/deanery/selectors';
import { churches, churchState } from './../../redux/features/church/selectors';
import { STATE } from '../../redux/constants';
import Search from "../../components/search/component";
import SidebarMain from "../../components/sidebar-main/component";
import SidebarWidget from "../../components/sidebar-widget/component";
import Sidebar from "../../components/sidebar/component";
import { NavLink } from "react-router-dom";

function Homepage(props) {
  useEffect(() => {
    document.title = props.title + " - Kirke-Foto" || "";
    const meta = document.getElementsByTagName('meta')
    meta['og:title'].content = document.title;
    meta['og:image'].content = "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    meta['og:description'].content = props.shortDescription || "Et billedgalleri over de danske kirker både fra landjorden og luften.";
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + props.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = props.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";
    getLatest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title]);

  const [latest, setLatest] = useState(null);
  const [drones, setDrones] = useState(0);
  const [loading, setLoading] = useState(0);

  function getLatest() {
    if (props.churchState !== STATE.IS_FETCHED) { return }
    if (props.parishState !== STATE.IS_FETCHED) { return }
    if (loading === 1) { return }
    setLoading(1);
    fetch("https://api.kirke-foto.dk/churches/latest", { credentials: 'include'})
      .then(res => res.json())
      .then(
          (result) => {
            const churches = result.map(image => {
              const church = props.churches.find(church => church.id === image.church_id);
              const parish = props.parishes.find(parish => parish.id === church.parish_id);
              church.parish = parish;

              return church
            });
            setLatest(churches);
            window.prerenderReady = true;
          },
          (error) => {
            console.log('ERROR');
            setLoading(0);
          }
      );
      fetch("https://api.kirke-foto.dk/churches/drones", { credentials: 'include'})
      .then(res => res.json())
      .then(
          (result) => {
            setDrones(result);
          },
          (error) => {
            console.log('ERROR');
            setLoading(0);
          }
      );
  }

  getLatest();

  const MainContent = (
    <>
      <h1>Velkommen til Kirke-Foto</h1>
      <p>Formålet med denne hjemmeside er, at dokumentere projektet: "Billedlig bevaring af de danske kirker".</p>
      <p>Billederne er originale og er til fri afbenyttelse, såfremt Kirke-Foto.dk er angivet som kilde.</p>
      <p style={{marginBottom:0}}>Der er i skrivende stund <b>{props.churches ? props.churches.length : 0}</b> kirker oprettet med billeder henover <b>{props.deaneries ? props.deaneries.length : 0}</b> provstier.</p>
      <p>Der er i alt <b>{drones ? drones : 0}</b> kirker med dronetilladelse fra tilhørende menighedsråd.</p>
      <Search />
    </>
  );

  return (
    <>
      <SidebarMain>{MainContent}</SidebarMain>
      <Sidebar>
        <SidebarWidget title="Seneste opdaterede">
          <ul className="latest-churches">
            {latest ? latest.map(church => (
              <li key={church.id}><NavLink to={"kirke/"+church.parish.url+"/"+church.url}>{church.name}</NavLink></li>
            )): null}
          </ul>
        </SidebarWidget>
      </Sidebar>
    </>
  );
}


const mapStateToProps = state => ({
  dioceses: dioceses(state),
  parishes: parishes(state),
  deaneries: deaneries(state),
  churches: churches(state),
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {})(Homepage);
