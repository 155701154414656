import "./style.scss";
import { NavLink } from "react-router-dom";

export default function render() {
    return (
        <header className="header">
            <NavLink to="/" className="logo">
                <img src="/logo.svg" alt="Logo" />
                <h1>Kirke-Foto</h1>
                {/*<form onSubmit={this.onFormSubmit} className="input-group">
                    <input onChange={this.onFormChange} className="form-control" type="text" />
                    <button className="btn btn-primary"><i className="fa fa-search"></i></button>
                </form>*/}
            </NavLink>
            <nav>
                <ul className="menu">
                    <li><NavLink to="/" className={({isActive}) => (isActive ? "active" : '')}>Forside</NavLink></li>
                    <li><NavLink to="/kort" className={({isActive}) => (isActive ? "active" : '')}>Kort</NavLink></li>
                    <li><NavLink to="/om-os" className={({isActive}) => (isActive ? "active" : '')}>Om os</NavLink></li>
                    <li><NavLink to="/kontakt" className={({isActive}) => (isActive ? "active" : '')}>Kontakt</NavLink></li>
                </ul>
            </nav>
        </header>
    );
}