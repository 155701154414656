import { DONE_FETCHED_PARISH } from "./constants";

export function setStatus(STATUS) {
    const action = {
        type: STATUS,
    };
    return action;
}

export function setPayload(payload) {
    const action = {
        type: DONE_FETCHED_PARISH,
        payload
    };
    return action;
}
