import { createSelector } from "reselect";
const stateI = state => state.church.state;
const churchesI = state => state.church.churches;
const loadedImagesI = state => state.church.loadedImages;
const showingImagesI = state => state.church.showingImages;

export const churchState = createSelector(
    [stateI],
    state => state
);

export const churches = createSelector(
    [churchesI],
    churches => churches
);

export const loadedImages = createSelector(
    [loadedImagesI],
    loadedImages => loadedImages
);

export const showingImages = createSelector(
    [showingImagesI],
    showingImages => showingImages
);
