import { createSelector } from "reselect";
const stateI = state => state.diocese.state;
const diocesesI = state => state.diocese.dioceses;

export const dioceseState = createSelector(
    [stateI],
    state => state
);

export const dioceses = createSelector(
    [diocesesI],
    dioceses => dioceses
);
