import React from "react";
import { withRouter } from "../../util";
import view from "./view";

import { connect } from "react-redux";
import { dioceses, dioceseState } from './../../redux/features/diocese/selectors';
import { parishes, parishState } from './../../redux/features/parish/selectors';
import { deaneries, deaneryState } from './../../redux/features/deanery/selectors';
import { churches, churchState } from './../../redux/features/church/selectors';
import { STATE } from '../../redux/constants';
import { setImages, setShowingImages } from "../../redux/features/church/actions";

/*function ChurchSingle(props) {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  let { parishUrl, churchUrl } = useParams();

  return (
    <div>
      <>
      Kirke Single {parishUrl} in {churchUrl}
      </>
    </div>
  );
}*/

class ChurchSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Kirke",
      keywords: "",
      description: "",
      parishUrl: "",
      churchUrl: "",
      church: null,
      parish: null,
      deanery: null,
      diocese: null,
      images: null,
      loading: true,
    }

  }

  componentDidMount() {
    this.setState({
      parishUrl: this.props.params.parishUrl,
      churchUrl: this.props.params.churchUrl,
    }, () => {
      this.getChurch();
    });
    document.title = this.state.title + " - Kirke-Foto" || "";
    const meta = document.getElementsByTagName('meta');
    meta['og:title'].content = document.title;
    if (this.state.images) {
      meta['og:image'].content = "https://kirke-foto.dk/images/high_"+ this.state.images[0].path || "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    } else {
      meta['og:image'].content = "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    }
    meta['og:description'].content = "Se billeder taget af " + this.state.title + " til Kirke-Foto.";
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + this.state.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = this.state.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.parishUrl !== this.props.params.parishUrl) {
      this.setState({
        parishUrl: this.props.params.parishUrl,
      }, () => {
        this.getChurch();
      });
    }
    if (prevProps.params.churchUrl !== this.props.params.churchUrl) {
      this.setState({
        churchUrl: this.props.params.churchUrl,
      }, () => {
        this.getChurch();
      });
    }
    if (prevProps.parishState !== this.props.parishState && this.props.parishState === STATE.IS_FETCHED) {
      this.getChurch();
    }

    if (prevProps.churchState !== this.props.churchState && this.props.churchState === STATE.IS_FETCHED) {
      this.getChurch();
    }

    if (prevProps.deaneryState !== this.props.deaneryState && this.props.deaneryState === STATE.IS_FETCHED) {
      this.getChurch();
    }

    if (prevProps.dioceseState !== this.props.dioceseState && this.props.dioceseState === STATE.IS_FETCHED) {
      this.getChurch();
    }
    
    document.title = this.state.title + " - Kirke-Foto" || "";
    const meta = document.getElementsByTagName('meta');
    meta['og:title'].content = document.title;
    if (this.state.images) {
      meta['og:image'].content = "https://kirke-foto.dk/images/high_"+ this.state.images[0].path || "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    } else {
      meta['og:image'].content = "https://kirke-foto.dk/images/high_P4Ai1Hv65iGQfrnPlXO70XP8TBch4wtcwlrL1DQ3.jpg";
    }
    meta['og:description'].content = "Se billeder taget af " + this.state.title + " til Kirke-Foto.";
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + this.state.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = this.state.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";
  }

  getChurch() {
    if (
      this.props.parishState === STATE.IS_FETCHED &&
      this.props.churchState === STATE.IS_FETCHED &&
      this.props.deaneryState === STATE.IS_FETCHED &&
      this.props.dioceseState === STATE.IS_FETCHED
    ) {
      const church = this.props.churches.find(church => church.url === this.state.churchUrl);
      fetch("https://api.kirke-foto.dk/churches/images/" + church.id)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                images: result
              }, () => {
                this.props.setImages(result);
                window.prerenderReady = true;
              });
            },
            (error) => {
              console.log('ERROR');
            }
      );
      const parish = this.props.parishes.find(parish => parish.url === this.state.parishUrl);
      const deanery = this.props.deaneries.find(deanery => deanery.id === parish.deanery_id);
      const diocese = this.props.dioceses.find(diocese => diocese.id === deanery.diocese_id);
      this.setState({
        church,
        parish,
        deanery,
        diocese,
        title: church.name,
        keywords: church.seo_tags,
        description: church.seo_description,
        loading: false,
      });
    }
  }

  openImage = index => {
    this.props.setShowingImages(index);
  }

  render = view;
}

const mapStateToProps = state => ({
  dioceses: dioceses(state),
  parishes: parishes(state),
  deaneries: deaneries(state),
  churches: churches(state),
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
});

export default connect(mapStateToProps, {setImages, setShowingImages})(withRouter(ChurchSingle));
