import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SidebarMain from "../../components/sidebar-main/component";
import SidebarWidget from "../../components/sidebar-widget/component";
import Sidebar from "../../components/sidebar/component";

function About(props) {
  useEffect(() => {
    document.title = props.title + " - Kirke-Foto" || "";
    const meta = document.getElementsByTagName('meta');
    meta['og:title'].content = document.title;
    meta['og:image'].content = "https://kirke-foto.dk/me_2.jpeg";
    meta['og:description'].content = props.shortDescription;
    meta.keywords.content = "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark, " + props.keywords || "kirke, foto, galleri, billeder, kirke-foto, kirkefoto, billed, kirke-galleri, kirkegalleri, dansk, danmark";
    meta.description.content = props.description || "Et billedgalleri over de danske kirker både fra landjorden og luften. Søg nemt imellem stifter, provstier, sogne og kirker og find netop din kirke frem.";
    window.prerenderReady = true;
  }, [props.title, props.shortDescription, props.description, props.keywords]);

  return (
    <>
      <SidebarMain className="about-us">
        <h1>Om os</h1>

        <p>Mit navn er René Dyhr, min store passion er fotografering af naturen, men inden for de senere år også fået en interesse indenfor dansk arkitektur, nærmere bevaringsværdige bygninger, lige fra gårde til kirker.</p>
        <p>På trods af at jeg ikke lever et religiøst liv, så finder jeg de danske kirker interessante med deres lange historie, som rækker tilbage til en tid der så meget anderledes ud, end som vi kender den i dag. Kirker har altid været et samlingspunkt for mennesker, både i lykke og i sorg.</p>
        <h3>Projektets udformning</h3>
        <p>Når jeg besøger en ny kirke, så starter jeg ud med at gå en runde om kirken med mit kamera, hvor jeg tager billeder af kirkens bygninger og kirkegården, der fanger stemningen som hvis man selv gik rundt.</p>
        <p>Med et fjernpilotcertifikat er det muligt for mig at sende en drone op i luften og dermed se kirken fra nye vinkler. For at kunne gøre dette er der nogle love og retningslinjer som jeg skal følge. Det vil blandt andet sige at der skal holdes afstand til privat grund.</p>
        <p>Hvis der forefindes dronebilleder hvor der overflyves kirkens matrikel, så har jeg fået tilladelse af menighedsrådet tilhørende kirken.</p>
        <p>Vil du læse mere om de regler der er vedr. flyvning af drone, kan du læse mere på <a href="https://www.retsinformation.dk/eli/lta/2020/2253" target="_blank" rel="noreferrer">Retsinformation omkring Dronebekendtgørelsen</a></p>
        <br />
        <p>Har du nogen spørgsmål til projektet eller de billeder der tages, så er du mere end velkommen til at <NavLink to="/kontakt">kontakte mig</NavLink>.</p>
      </SidebarMain>
      <Sidebar>
        <SidebarWidget noPadding>
          <img src="/me_1.jpeg" alt="René Dyhr" className="image about-us-image" />
        </SidebarWidget>
      </Sidebar>
    </>
  );
}

export default About;
