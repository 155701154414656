import React from 'react';
import { connect } from 'react-redux';
import view from "./view";
import { loadedImages, showingImages } from './../../redux/features/church/selectors';
import { setShowingImages } from "./../../redux/features/church/actions";

class Photos extends React.Component {
    _isMounted = false;
    _swipeVelocity = 80;
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            /* Used for touch swipe events. */
            left: 0,
            velocity: 0,
            timeOfLastDragEvent: 0,
            touchStartX: 0,
            prevTouchX: 0,
            beingTouched: false,
            loading: true,
        };
    }

    /*
    When the component is mounted, we set it to true and then loads the photo
    that's sent to us through the props.
    After we've loaded that, we will load all photos in full res
    Last but not least, we will sort out the index and the index helper to keep track of our positions.
    */
    componentDidMount() {
        this._isMounted = true;
            
        document.body.addEventListener("keydown", this.onKeyPress);
        let index = 0;
        if (this._isMounted) {
            this.setState({
                index
            });
        }
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showingImages !== this.props.showingImages) {
            if (this.props.showingImages >= 0) {
                this.setState({
                    index: this.props.showingImages
                }, () => {
                    if (this.props.images[this.state.index] && this.props.images[this.state.index].panorama) {
                        this.panorama(this.props.images[this.state.index].path);
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.removeEventListener("keydown", this.onKeyPress);
    }

    onImageLoad = e => {
        this.setState({
            loading: false,
        });
    }


    panorama = (path) => {
        this.setState({
            loading: false,
        });
        const viewer = window.pannellum.viewer('panorama', {
            // "autoRotate": 2,
            // "autoRotateInactivityDelay": 10000,
            "showControls": false,
            "type": "equirectangular",
            "autoLoad": true,
            "disableKeyboardCtrl": true,
            "panorama": "/images/high_"+path
        });
    
        viewer.on('load', this.onImageLoad)

        document.getElementById('pan-up').addEventListener('click', function(e) {
            viewer.setPitch(viewer.getPitch() + 10);
        });
        document.getElementById('pan-down').addEventListener('click', function(e) {
            viewer.setPitch(viewer.getPitch() - 10);
        });
        document.getElementById('pan-left').addEventListener('click', function(e) {
            viewer.setYaw(viewer.getYaw() - 10);
        });
        document.getElementById('pan-right').addEventListener('click', function(e) {
            viewer.setYaw(viewer.getYaw() + 10);
        });
        document.getElementById('zoom-in').addEventListener('click', function(e) {
            viewer.setHfov(viewer.getHfov() - 10);
        });
        document.getElementById('zoom-out').addEventListener('click', function(e) {
            viewer.setHfov(viewer.getHfov() + 10);
        });
        document.getElementById('fullscreen').addEventListener('click', function(e) {
            viewer.toggleFullscreen();
        });
    }

    touchStart(e) {
        if (this.props.images.length > 0) {
            this.setState({
                originalOffset: this.state.left,
                velocity: 0,
                timeOfLastDragEvent: Date.now(),
                touchStartX: e.targetTouches[0].clientX,
                beingTouched: true,
            });
        }
    }

    touchMove(e) {
        if (this.state.beingTouched && this.props.images.length > 0 && !this.props.images[this.state.index].panorama) {
            const touchX = e.targetTouches[0].clientX;
            const currTime = Date.now();
            const elapsed = currTime - this.state.timeOfLastDragEvent;
            const velocity = (20 * (touchX - this.state.prevTouchX)) / elapsed;
            const deltaX = touchX - this.state.touchStartX;
            if (deltaX < -this._swipeVelocity) {
                this.goRight();
                this.setState({
                    left: 0,
                    velocity: 0,
                    touchStartX: 0,
                    timeOfLastDragEvent: 0,
                    prevTouchX: 0,
                    beingTouched: false
                });
            } else if (deltaX > this._swipeVelocity) {
                this.goLeft();
                this.setState({
                    left: 0,
                    velocity: 0,
                    touchStartX: 0,
                    timeOfLastDragEvent: 0,
                    prevTouchX: 0,
                    beingTouched: false
                });
            }
            this.setState({
                left: deltaX,
                velocity,
                timeOfLastDragEvent: currTime,
                prevTouchX: touchX
            });
        }
    }

    touchEnd() {
        if (this.props.images.length > 0) {
            this.setState({
                velocity: this.state.velocity,
                touchStartX: 0,
                beingTouched: false,
            });
        }
    }

    onKeyPress = e => {
        if (e.keyCode === 27) {
            this.props.setShowingImages(null);
            e.preventDefault();
            e.stopPropagation();
            if (e.stopImmediatePropagation) {
                e.stopImmediatePropagation();
            }
        }
        if (e.keyCode === 37 || e.keyCode === 39) {
            if (e.keyCode === 37) {
                this.goLeft();
            } else if (e.keyCode === 39) {
                this.goRight();
            }

            e.preventDefault();
            e.stopPropagation();
            if (e.stopImmediatePropagation) {
                e.stopImmediatePropagation();
            }
        }
    }

    /*
    When we're swiping or clicking the go left button.
    We will check if the next occurence exists in the index helper
    If it isn't defined, we suppose the user got the first photo in the array
    We will therefor go to the last photo in the array.
    If not, we will get the correct picture out.
    */
    goLeft = () => {
        const index = this.state.index - 1;
        const lastIndex = this.props.images.length - 1;
        if (this.state.index === 0) {
            this.setState({
                index: lastIndex,
                loading: true,
            }, () => {
                if (this.props.images[this.state.index].panorama) {
                    this.panorama(this.props.images[this.state.index].path);
                }
            });
        } else {
            this.setState({
                index: index,
                loading: true,
            }, () => {
                if (this.props.images[this.state.index].panorama) {
                    this.panorama(this.props.images[this.state.index].path);
                }
            });
        }
    }

    /*
    When we're swiping or clicking the go right button.
    We will check if the next occurence exists in the index helper
    If it isn't defined, we suppose the user got the last photo in the array
    We will therefor go to the first photo in the array.
    If not, we will get the correct picture out.
    */
    goRight = () => {
        const index = this.state.index + 1;
        const lastIndex = this.props.images.length - 1;
        if (this.state.index === lastIndex) {
            this.setState({
                index: 0,
                loading: true,
            }, () => {
                if (this.props.images[this.state.index].panorama) {
                    this.panorama(this.props.images[this.state.index].path);
                }
            });
        } else {
            this.setState({
                index: index,
                loading: true,
            }, () => {
                if (this.props.images[this.state.index].panorama) {
                    this.panorama(this.props.images[this.state.index].path);
                }
            });
        }
    }


    render = view;
}

const mapStateToProps = state => ({
    images: loadedImages(state),
    showingImages: showingImages(state),
  });
  
export default connect(mapStateToProps, {setShowingImages})(Photos);
