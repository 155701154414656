import { DONE_FETCHED_CHURCH, SET_IMAGES, SHOW_IMAGES } from "./constants";

export function setStatus(STATUS) {
    const action = {
        type: STATUS,
    };
    return action;
}

export function setPayload(payload) {
    const action = {
        type: DONE_FETCHED_CHURCH,
        payload
    };
    return action;
}


export function setImages(payload) {
    const action = {
        type: SET_IMAGES,
        payload
    };
    return action;
}


export function setShowingImages(payload) {
    const action = {
        type: SHOW_IMAGES,
        payload
    };
    return action;
}
