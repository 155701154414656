import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from './pages/homepage/component';
import About from './pages/about/component';
import Map from './pages/map/component';
import Header from "./components/header/component";
import Contact from './pages/contact/component';
import ChurchSingle from './pages/church-single/component';
import Content from './components/content/component';
import React from 'react';
import { connect } from "react-redux";
import { dioceseState } from './redux/features/diocese/selectors';
import { setStatus as setStatusDiocese, setPayload as setPayloadDiocese } from './redux/features/diocese/actions';
import { setStatus as setStatusParish, setPayload as setPayloadParish } from './redux/features/parish/actions';
import { setStatus as setStatusDeanery, setPayload as setPayloadDeanery } from './redux/features/deanery/actions';
import { setStatus as setStatusChurch, setPayload as setPayloadChurch } from './redux/features/church/actions';
import { FAILED_FETCHED_DIOCESE, FETCH_DIOCESE } from './redux/features/diocese/constants';
import { FAILED_FETCHED_PARISH, FETCH_PARISH } from './redux/features/parish/constants';
import { FAILED_FETCHED_DEANERY, FETCH_DEANERY } from './redux/features/deanery/constants';
import { FAILED_FETCHED_CHURCH, FETCH_CHURCH } from './redux/features/church/constants';
import { parishState } from './redux/features/parish/selectors';
import { deaneryState } from './redux/features/deanery/selectors';
import { churchState, showingImages } from './redux/features/church/selectors';
import { STATE } from './redux/constants';
import NotFound from './pages/not-found/component';
import Footer from './components/footer/component';


class App extends React.Component {

  componentDidMount() {
    if (this.props.dioceseState !== STATE.IS_FETCHED) {
      this.props.setStatusDiocese(FETCH_DIOCESE);
      fetch("https://api.kirke-foto.dk/dioceses")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadDiocese(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_DIOCESE);
            }
        );
    }
    if (this.props.parishState !== STATE.IS_FETCHED) {
      this.props.setStatusParish(FETCH_PARISH);
      fetch("https://api.kirke-foto.dk/parishes")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadParish(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_PARISH);
            }
        );
    }
    if (this.props.deaneryState !== STATE.IS_FETCHED) {
      this.props.setStatusDeanery(FETCH_DEANERY);
      fetch("https://api.kirke-foto.dk/deaneries")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadDeanery(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_DEANERY);
            }
        );
    }
    if (this.props.churchState !== STATE.IS_FETCHED) {
      this.props.setStatusChurch(FETCH_CHURCH);
      fetch("https://api.kirke-foto.dk/churches")
        .then(res => res.json())
        .then(
            (result) => {
              this.props.setPayloadChurch(result);
            },
            (error) => {
              this.props.setStatus(FAILED_FETCHED_CHURCH);
            }
        );
    }
  }


  render() {
    return (
      <div className={['main', this.props.showingImages !== null ? 'showing-images' : ''].join(' ')}>
        <Router>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Header />
            <Content>
              <Routes>
                <Route path="/" exact element={<Homepage title="Forside" />} />
                <Route path="/kort" exact element={<Map title="Kort" keywords="kort, danmarkskort, kort over kirker" shortDescription="Se et Danmarks kort over kirker der ligger på Kirke-Foto.dk" description="Her kan du se et Danmarks kort over de kirker der er blevet fotograferet til Kirke-Foto.dk og nemt klikke ind og se billederne." />} />
                <Route path="/om-os" exact element={<About title="Om os" keywords="om os, om mig, rené dyhr, om kirke-foto" shortDescription="Læs mere om Kirke-Foto blev til, historien bag opstarten." description="Læs mere om hvordan Kirke-Foto blev til, historien bag opstarten, hvem René Dyhr er samt hvorfor han tager rundt og tager billeder af Danmarks kirker." />} />
                <Route path="/kontakt" exact element={<Contact title="Kontakt" keywords="kontakt, kontakt rené dyhr, kontakt kirke-foto" shortDescription="Få kontakt til Kirke-Foto." description="Vil du gerne i kontakt med Kirke-Foto? Så har du her muligheden for at sende en besked." />} />
                <Route path="/kirke/:parishUrl/:churchUrl" exact element={<ChurchSingle />} />
                <Route path="*" element={<NotFound title="Siden er ikke fundet" />} />
              </Routes>
            </Content>
            <Footer />
        </Router>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dioceseState: dioceseState(state),
  parishState: parishState(state),
  deaneryState: deaneryState(state),
  churchState: churchState(state),
  showingImages: showingImages(state),
});

export default connect(mapStateToProps, {
  setStatusDiocese,
  setPayloadDiocese,
  setStatusParish,
  setPayloadParish,
  setStatusDeanery,
  setPayloadDeanery,
  setStatusChurch,
  setPayloadChurch,
})(App);
